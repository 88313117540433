export const categoriesData = {
  "categories": [
    {
      "name": "News",
      "subcategories": ["General News", "World News"]
    },
    {
      "name": "Finance",
      "subcategories": ["Finance", "Economics"]
    },
    {
      "name": "Tech News",
      "subcategories": ["Technology", "AI", "Futurology", "Android", "Apple", "Science", "Games", "Gaming", "PC Gaming", "Programming"]
    },
    {
      "name": "Catered News",
      "subcategories": ["Conservative News", "Liberal News", "Environment"]
    },
    {
      "name": "Off Beat News",
      "subcategories": ["Off Beat News", "Uplifting News", "Oddly Interesting News", "Conspiracy", "Florida Man"]
    },
    {
      "name": "Sports",
      "subcategories": ["Sports", "NFL", "Soccer", "Hockey", "NBA"]
    },
    {
      "name": "Entertainment",
      "subcategories": ["Movies", "Television", "Books"]
    },
    {
      "name": "US Local News",
      "subcategories": [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
        'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
      ]
    },
    {
      "name": "Canada Local News",
      "subcategories": [
        'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
        'Newfoundland and Labrador', 'Nova Scotia', 'Ontario',
        'Prince Edward Island', 'Quebec', 'Saskatchewan'
      ]
    },

  ]
};

export default categoriesData;